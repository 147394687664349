import React from 'react';
import githubimg from '../images/GitHub.png'
import mongodbimg from '../images/mongodb.png'
import nodeimg from '../images/node.png'
import favicon from '../images/favicon.ico'
import '../css/Skills.css'

const Skills = (props) => {

const redirectTo =(page)=>{
    window.open(page, '_blank');
}
const skillflex=`
Git - Redux - NoSql - SQL - React - JavaScript - TypeScript - Node.js - Redis -
Cloudinary - Rest - Graphql - GitHub - CSS - HTML - MongoDB`

    return (
        <div id='aboutdiv' className='skillcontainer' data-colortomenu='black'> 
            <h2 className='skillbase anim' data-anim='appearanim'>About</h2>
            <p className='skillbase introduceyourself anim' data-anim='appearanim'>
                Frontend with React, backend with Node.js. I can do both of them with either
                javascript or typescript. I build my projects keeping mobile
                devices in mind so they work on all normal device sizes. Real enthusiast to getting 
                into the core of javascript and a GraphQl fan.
            </p>
            <div className='skillbase skillitem js anim' data-anim='leftanim'>JavaScript</div>
            <div className='skillbase skillitem ts anim' data-anim='leftanim'>TypeScript</div>
            <div className='skillbase skillitem css anim' data-anim='leftanim'>CSS</div>
            <div className='skillbase skillitem html anim' data-anim='leftanim'>HTML</div>
            <img className='skillbase fav anim' src={favicon} alt='notfound' data-anim='rightanim'></img>
            <img className='skillbase skilllogo anim' src={nodeimg} alt='notfound' data-anim='rightanim'></img>
            <img className='skillbase skilllogo anim' src={mongodbimg} alt='notfound' data-anim='rightanim'></img>
            <div className='skillbase plainskillitem anim' data-anim='rightanim'>Git</div>
            <br></br>
            <img onClick={()=>redirectTo('https://github.com/jannepetter')}
             className='skillbase skilllogo github anim' src={githubimg} alt='notfound' data-anim='rightanim'></img>
            <div className='skillbase speechbubble' data-anim='leftanim'>Check out my github profile</div>
            <br></br>
            <div className='skillbase skillflexcontainer anim' data-anim='rightanim'>
            <div className='skillflex'>{skillflex}</div>
            </div>
            
        </div>)
}

export default Skills