import React from 'react';
import './css/App.css'
import Demos from './components/Demos'
import Skills from './components/Skills';
import Header from './components/Header'
import Menubar from './components/Menubar';
import {Animations} from './utils/Animations'
function App() {

Animations()

  return (
    <div className='appcontainer'>
      <Header></Header>
      <div className='menubarsdomain'>
      <Menubar></Menubar>
      <Skills ></Skills>
      <Demos></Demos>
      </div>
    </div>
  );
}

export default App;

//animaatiot, sait melkein menubarit vaihtaan väriä oikein
//sitte lisää animaatioita