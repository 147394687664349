
export const MenuAnim =()=>{
    const skillw=document.querySelector('.skillcontainer')
    const demosw=document.querySelector('.demoscontainer')
    const headerw=document.querySelector('.headerContainer')
    const containers=[skillw,demosw,headerw]

    const menuitems=document.getElementsByClassName('menuitem')
    const options={
        rootMargin: '0% 0% -96% 0%',
        threshold:0.02
    }
    const obs=new IntersectionObserver(entries=>{
        entries.forEach(e=>{
            if(e.isIntersecting===true){
                changeColor(menuitems,e.target.dataset.colortomenu)
            }
        });
    },options)
  

    containers.forEach(item=>{
    obs.observe(item)
    })
    //obs.observe(skillw)   //tai voit laittaa yksitellenki
    //obs.observe(demosw)
    //obs.observe(headerw)
}
function changeColor(collection, color){
    for(let i=0; i<collection.length;i++){
        collection[i].style["color"] = color;
    }
  }