import React from 'react';
import '../css/Menubar.css'

const Menubar = (props) => {
    const scroll = (location) => {
        document.getElementById(location).scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div id='menubarcontainer' className='menubarcontainer'>
            <div onClick={() => scroll('headercontainer')} className='menuitem'>Top</div>
            <div onClick={() => scroll('aboutdiv')} className='menuitem'>About</div>
            <div onClick={() => scroll('demoscontainer')} className='menuitem'>Projects</div>
        </div>)
}

export default Menubar