
export const ItemAnims =()=>{
    const items=document.querySelectorAll('.anim')
    const options={
    }
    const obs=new IntersectionObserver(entries=>{
        entries.forEach(e=>{
            if(e.isIntersecting){
                e.target.classList.add(e.target.dataset.anim)
            }else{
                e.target.classList.remove(e.target.dataset.anim)
            }
            
        });
    },options)
  

    items.forEach(item=>{
    obs.observe(item)
    })
}