import React from 'react';
import '../css/Header.css'

const Header = (props) => {
    let i = 0;
    const txt = `Hi! , My name is JannePetter. \t\t\t  
    Im a web developer and this is my online portfolio! `

    var speed = 75;

    function typeWriter() {
        if (i < txt.length) {
            document.getElementById("demo").innerHTML += txt.charAt(i);
            i++;
            setTimeout(typeWriter, speed);
        }
    }

    const waiting = () => {        //for css animation to appear first
        setTimeout(() => {
            typeWriter()
        }, 3500);
    }
    window.addEventListener('DOMContentLoaded', waiting())

    const scroll = (location) => {
        document.getElementById(location).scrollIntoView({behavior:'smooth'});
    }


    return (
        <div id='headercontainer' className='headerContainer' data-colortomenu='white'>
            <h1>Portfolio</h1>
            <div className='typer' id="demo"></div>
            <div onClick={() => scroll('aboutdiv')} className='rollahead'>continue</div>
        </div>)
}

export default Header