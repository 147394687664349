import React from 'react';
import pokemon from '../images/pokemon.jpg'
import globe from '../images/globe.jpg'
import railways from '../images/railways.jpg'
import restaurant from '../images/restaurant.jpg'
import pingpong from '../images/pingpong.jpg'
import ouluinfo from '../images/ouluinfo.jpg'

import DemoItem from './DemoItem';
import '../css/Demos.css'

const Demos = (props) => {
    const url = 'https://jannepetter.github.io'

    const poketext = 'Pokemoninfo application, using open pokemon rest api. Go and check it out!'
    const countrytext = 'Application about countries, using open countries rest api.'
    const railtext = 'Finland railway schedules app, using digitransit open graphql api.'
    const restaurantText = 'Webpagedemo for small restaurant.'
    const pong = 'Classic pong game'
    const oulu = 'Demo using open data on Oulu graphql endpoint'

    return (
        <div id='demoscontainer' className='demoscontainer' data-colortomenu='white'>
            <h2 className='demoheadline anim' data-anim='appearanim'>Projects</h2>
            <DemoItem url={url + '/react-redux-pokeapi-js-example/'} className='demolistItem'
                image={pokemon} text={poketext} dataAnim='leftanim'></DemoItem>
            <DemoItem url={url + '/react-redux-rest-typescript-example/'} className='demolistItem'
                image={globe} text={countrytext} dataAnim='leftanim'></DemoItem>
            <DemoItem url={url + '/transportapp-react-graphql-example/'} className='demolistItem'
                image={railways} text={railtext} dataAnim='rightanim'></DemoItem>
            <DemoItem url={url + '/businesslandingpage-demo/'} className='demolistItem'
                image={restaurant} text={restaurantText} dataAnim='leftanim'></DemoItem>
            <DemoItem url={url + '/pingpong/'} className='demolistItem'
                image={pingpong} text={pong} dataAnim='leftanim'></DemoItem>
            <DemoItem url={url + '/ouluInfo-demo/'} className='demolistItem'
                image={ouluinfo} text={oulu} dataAnim='leftanim'></DemoItem>
        </div>)
}

export default Demos