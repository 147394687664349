import React from 'react';
import '../css/DemoItem.css'

const DemoItem =({image,text,url,dataAnim})=>{ 
    const redirectToPage =(page)=>{
        window.open(page, '_blank');
    }
return(
<div onClick={()=>redirectToPage(url)} className='demoitemcontainer anim' data-anim={dataAnim} >
<img  className='demoitemImg' src={image} alt='none'></img>
<p className='demoitemText'>{text}</p>
</div>)
}

export default DemoItem